import { LANDING_PAGE_PATHS } from 'constants/landing-page';
import React from 'react';
import BaseLandingPage from 'style-book/landing-page/BaseLandingPage';
import SeoComponent from 'style-book/seo/SeoComponent';
import { getStoredLandingPage, lpServiceByPath } from 'utils/lp-utils';

import './index.css';

function GoalTrackingPage() {
  return <BaseLandingPage lpService={lpServiceByPath[LANDING_PAGE_PATHS.GOAL_TRACKING]} />;
}

export default GoalTrackingPage;

export function Head() {
  const lpService = lpServiceByPath[LANDING_PAGE_PATHS.GOAL_TRACKING];
  return (
    <SeoComponent
      title={lpService.getSeoTitle()}
      description="Transforming Data into Success: Goalskeeper's Powerful Solution. Set Goals, Visualize Insights, and Achieve Business Milestones."
      canonicalUrl="goalskeeper.io/goal-tracking/"
    />
  );
}
